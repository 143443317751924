<template>
  <div class="enterprise-form wrapper">
    <a-spin :spinning="loading">
      <div class="">
        <a-empty
          v-if="enterprise_form.length == 0"
          description="暂无填报数据"
        ></a-empty>
        <div v-else class="">
          <template v-for="(item, index) in enterprise_form" :key="index">
            <div class="border-line">
              <div
                class="form-item flex flex-between"
                @click="goToEnterpriseDetail(item)"
              >
                <div>
                  <div class="font-18 font-bold mb-10">
                    {{ item.enterprise_name }}
                  </div>
                  <div class="font-18">{{ item.form_year }}年{{ item.form_month }}月</div>
                </div>
                <div class="font-18 text-gray">
                  {{ item.form_status == 1 ? "已填报" : "未填报"
                  }}<right-outlined />
                </div>
              </div>
              <!-- <div class="form-content text-gray" v-if="item.form_status == 1">
                <div>累计收入：{{ item.form_income }}万元</div>
                <div>累计纳税：{{ item.form_tax }}万元</div>
                <div>就业人数：{{ item.form_employment }}万元</div>
              </div> -->
            </div>
          </template>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getEnterpriseFormList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId } from "@/utils/storeData.js";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "EnterpriseFormList",
  components: {
    RightOutlined
  },
  setup() {
    const pageData = reactive({
      enterprise_form: [],
      user_id: computed(() => getUserId()),
      loading: false
    });
    const getEnterpriseFormListFun = () => {
      pageData.loading = true;
      getEnterpriseFormList({
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.enterprise_form = res.data.enterprise_form;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getEnterpriseFormListFun();
    const goToEnterpriseDetail = _item => {
      url.navigateTo("/enterprise/formDetail", {
        t: encode(`${_item.form_id}`)
      });
    };

    return {
      ...toRefs(pageData),
      goToEnterpriseDetail
    };
  }
});
</script>
<style lang="less" scoped>
.enterprise-form {
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .border-line {
    border-bottom: 20px solid #f3f5f8;
  }
  .border-line:last-child {
    border-bottom: none;
  }
  .form-item {
    padding: 20px 0;
    border-bottom: 1px solid #dfdfdf;
    cursor: pointer;
  }
  .form-content {
    padding: 15px 0;
    font-size: 18px;
    line-height: 40px;
  }
}
</style>
